import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'
import { Event } from '@/models/Event'

import { getUpcomingEvents } from './getUpcomingEvents'
import { UpcomingEventCard } from './UpcomingEventCard'

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  title: {
    marginBottom: spacing(6),
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
    color: palette.text.primary,
    fontSize: '1.5rem',
    letterSpacing: '0.03125em',
    lineHeight: 1.5,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
}))

type UpcomingEventsSectionProps = {
  events: Event[]
}

export const UpcomingEventsSection: FC<UpcomingEventsSectionProps> = ({
  events,
}) => {
  const classes = useStyles()
  const upcomingEvents = getUpcomingEvents(events)

  return (
    <PageContainer shadow>
      <SectionTitle className={classes.title}>Upcoming Events</SectionTitle>
      {upcomingEvents.length > 0 ? (
        upcomingEvents.map((event) => (
          <UpcomingEventCard
            key={`${event.id}-${event.starts_at}`}
            event={event}
          />
        ))
      ) : (
        <div className={classes.placeholder}>
          Stay connected to our church family through other events listed in the
          calendar below.
        </div>
      )}
    </PageContainer>
  )
}

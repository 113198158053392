import { EventApi } from '@fullcalendar/react'
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import {
  CalendarTodayOutlined,
  HighlightOff,
  RoomOutlined,
  ScheduleOutlined,
} from '@material-ui/icons'
import { DateTime } from 'luxon'
import React, { FC } from 'react'

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  paper: {
    overflowY: 'unset',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      padding: spacing(1, 2),
    },
  },
  titleText: {
    fontSize: '1rem',
    fontWeight: typography.fontWeightBold,
    [breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#dddddd',
    transform: 'translate(50%, -50%)',
  },
  content: {
    [breakpoints.down('xs')]: {
      padding: spacing(1, 2),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    fontSize: '0.875rem',
    [breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      marginBottom: spacing(2),
    },
  },
  icon: {
    marginRight: spacing(2),
    fontSize: '1.5em',
    [breakpoints.down('sm')]: {
      marginRight: spacing(1),
    },
  },
}))

type CalendarEventPopupProps = DialogProps & {
  event: EventApi | null
  onClose: () => void
}

export const CalendarEventPopup: FC<CalendarEventPopupProps> = ({
  event,
  onClose,
  ...rest
}) => {
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  if (!event) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dt = DateTime.fromJSDate(event.start!)

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      {...rest}
    >
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <HighlightOff />
      </IconButton>
      <DialogTitle className={classes.title}>
        <span className={classes.titleText}>{event.title}</span>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <CalendarTodayOutlined className={classes.icon} />
          <span>{dt.toFormat('EEE, MMM dd, yyyy')}</span>
        </div>
        <div className={classes.row}>
          <ScheduleOutlined className={classes.icon} />
          <span>{dt.toFormat('HH:mm')}</span>
        </div>
        <div className={classes.row}>
          <RoomOutlined className={classes.icon} />
          <span>{event.extendedProps.location}</span>
        </div>
      </DialogContent>
    </Dialog>
  )
}

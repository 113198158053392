import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { PageCover } from '@/components/common/PageCover'
import { CalendarSection } from '@/components/events/CalendarSection'
import { UpcomingEventsSection } from '@/components/events/UpcomingEventsSection'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "events" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    events: allStrapiEvents(sort: { fields: [starts_at], order: ASC }) {
      nodes {
        name
        description
        starts_at
        ends_at
        location
        photo {
          localFile {
            url
          }
        }
        recurring
        until
        link
      }
    }
  }
`

const EventsPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)
  const events = data.events.nodes.map(
    ({ strapiId, starts_at, ends_at, until, photo, ...rest }) => ({
      id: strapiId,
      starts_at: new Date(starts_at),
      ends_at: ends_at && new Date(ends_at),
      until: until && new Date(until),
      photo_url: photo?.localFile.url,
      ...rest,
    })
  )

  return (
    <>
      <SEO title="Events" />
      <PageCover image={image} title="Events" />
      <UpcomingEventsSection events={events} />
      <CalendarSection events={events} />
    </>
  )
}

export default EventsPage

import { EventInput } from '@fullcalendar/react'
import { DateTime } from 'luxon'

import { Event } from '@/models/Event'

import { getRRule } from './getRRule'

export function mapEventsToCalendar(events: Event[]) {
  return events.map((event) => mapEvent(event))
}

function mapEvent(event: Event): EventInput {
  const { description, location, starts_at, ends_at } = event

  const extendedProps = {
    description: event.description,
    location: event.location,
  }
  const rrule = getRRule(event)

  return {
    title: event.name,
    extendedProps,
    ...(ends_at
      ? {
          duration: DateTime.fromJSDate(ends_at).diff(
            DateTime.fromJSDate(starts_at)
          ),
        }
      : {}),
    ...(rrule ? { rrule } : { start: event.starts_at }),
  }
}

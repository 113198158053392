import { Button, makeStyles } from '@material-ui/core'
import { DateTime } from 'luxon'
import React, { FC, MouseEvent } from 'react'

import { Event } from '@/models/Event'

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    color: palette.text.primary,
    letterSpacing: '0.009333em',
    '&:not(:last-of-type)': {
      marginBottom: spacing(8),
    },
  },
  photoWrapper: {
    position: 'relative',
    flex: 1,
    maxWidth: '50%',
  },
  photo: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  date: {
    position: 'absolute',
    top: spacing(3),
    right: spacing(3),
    height: 96,
    width: 96,
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.5)',
    padding: spacing(1),
    backgroundColor: palette.background.paper,
    color: palette.text.primary,
    textAlign: 'center',
    lineHeight: 1.133,
  },
  month: {
    marginBottom: spacing(1),
    fontSize: '1.25rem',
  },
  day: {
    fontSize: '1.875rem',
  },
  info: {
    flex: 1,
    padding: spacing(3, 6),
  },
  title: {
    fontSize: '1.875rem',
  },
  divider: {
    height: 3,
    margin: spacing(1, 0, 4),
    backgroundColor: palette.secondary.main,
  },
  description: {
    marginBottom: spacing(4),
    fontSize: '1.125rem',
  },
  row: {
    marginBottom: spacing(2),
    fontSize: '1.125rem',
  },
  button: {
    margin: spacing(10, 0, 1),
    [breakpoints.down('sm')]: {
      margin: spacing(1, 0),
    },
  },
}))

type UpcomingEventCardProps = {
  event: Event
  onClickOpen?: (event: MouseEvent) => void
}

export const UpcomingEventCard: FC<UpcomingEventCardProps> = ({
  event,
  onClickOpen,
  ...rest
}) => {
  const classes = useStyles()
  const dt = DateTime.fromJSDate(event.starts_at)

  return (
    <div className={classes.root} {...rest}>
      <div className={classes.photoWrapper}>
        <img
          className={classes.photo}
          src={event.photo_url ?? ''}
          alt={event.name}
        />
        <div className={classes.date}>
          <div className={classes.month}>
            {dt.toFormat('MMM').toUpperCase()}
          </div>
          <div className={classes.day}>{dt.toFormat('d')}</div>
        </div>
      </div>
      <div className={classes.info}>
        <div className={classes.title}>{event.name}</div>
        <div className={classes.divider} />
        <p className={classes.description}>{event.description}</p>
        <div className={classes.row}>Date: {dt.toFormat('MMMM dd, yyyy')}</div>
        <div className={classes.row}>Time: {dt.toFormat('HH:mm')}</div>
        <div className={classes.row}>Location: {event.location}</div>
        {/* <Button className={classes.button} onClick={onClickOpen}>
          Register
        </Button> */}
      </div>
    </div>
  )
}

import { RRule } from 'rrule'

import { Event } from '@/models/Event'

import { getRRule } from './getRRule'

export function getUpcomingEvents(events: Event[]) {
  return events
    .flatMap((event) => expandEvent(event))
    .filter((event) => isUpcomingEvent(event))
    .sort(compareEventsByStartDate)
    .slice(0, 5)
}

function expandEvent(event: Event): Event[] {
  const rrule = getRRule(event)

  if (!rrule) {
    return [event]
  }

  const dates = RRule.fromString(rrule).all()
  return dates.map((date) => ({
    ...event,
    starts_at: new Date(date),
  }))
}

function isUpcomingEvent(event: Event) {
  return new Date(event.starts_at).getTime() >= new Date().setHours(0, 0, 0, 0)
}

function compareEventsByStartDate(a: Event, b: Event) {
  if (a.starts_at < b.starts_at) {
    return -1
  } else if (a.starts_at > b.starts_at) {
    return 1
  } else {
    return 0
  }
}

/* eslint-disable simple-import-sort/imports */
// Import sorting disabled because plugin depends on top-level component

import React, { FC, useState } from 'react'
import FullCalendar, { EventClickArg, EventApi } from '@fullcalendar/react'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'

import { PageContainer } from '@/components/common/PageContainer'
import { mapEventsToCalendar } from '@/components/events/mapEventsToCalendar'
import { Event } from '@/models/Event'

import { CalendarEventPopup } from './CalendarEventPopup'

type CalendarSectionProps = {
  events: Event[]
}

export const CalendarSection: FC<CalendarSectionProps> = ({ events }) => {
  const [selectedEvent, setSelectedEvent] = useState<EventApi | null>(null)

  function handleEventClick(eventInfo: EventClickArg) {
    setSelectedEvent(eventInfo.event)
  }

  function handleClose() {
    setSelectedEvent(null)
  }

  return (
    <PageContainer background="default">
      <FullCalendar
        plugins={[rrulePlugin, dayGridPlugin]}
        initialView="dayGridMonth"
        events={mapEventsToCalendar(events)}
        eventDisplay="list-item"
        displayEventTime={false}
        eventClick={handleEventClick}
      />
      <CalendarEventPopup
        open={Boolean(selectedEvent)}
        onClose={handleClose}
        event={selectedEvent}
      />
    </PageContainer>
  )
}
